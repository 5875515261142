
import Home from "./routes/home-cz/home.component";

import "./App.scss"

const App = () => {
  return (
      <Home />
  );
};
export default App;