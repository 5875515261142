import React, { useState } from "react";
import { FaBars, FaTimes} from "react-icons/fa";
import { NavHashLink } from "react-router-hash-link";
import "./navbar.scss";
import i18n from "../../i18n";
import LocaleContext from "../../LocaleContext";
import {ReactComponent as Logo} from "../../assets/reference/new.svg"
import { useTranslation} from 'react-i18next'

const Navbar = () => {
  const [isActive, setActive] = useState(false);
  const { t } = useTranslation();

  const handleToggle = () => {
    setActive(!isActive);
  };

  const closeToggle = () => {
    setActive(false)
    
  }
  const [locale, setLocale] = useState(i18n.language)
  i18n.on('languageChanged',(lng)=> setLocale(i18n.language))

  const handleChange = (event)=> {
    i18n.changeLanguage(event.target.value)
  }

  return (
    <LocaleContext.Provider value={{locale, setLocale}}>
    <nav className="navbar">
      <div className="navbar-logo">
        <Logo />
      </div>
      <ul className={`navbar-menu ${isActive ? 'active1' : ''}`}>
        <li>
          <NavHashLink to="#about" onClick={closeToggle}>{t('nav.about')}</NavHashLink>
        </li>
        <li>
          <NavHashLink to="#service" onClick={closeToggle}>{t('nav.services')}</NavHashLink>
        </li>
        <li>
          <NavHashLink to="#career" onClick={closeToggle}>{t('nav.career')}</NavHashLink>
        </li>
        <li>
          <NavHashLink to="#reference" onClick={closeToggle}>{t('nav.reference')}</NavHashLink>
        </li>
        <li>
          <NavHashLink to="#contact" onClick={closeToggle}>{t('nav.contact')}</NavHashLink>
        </li>
        <li>
          <select className="custom-select" value={locale} onChange={handleChange}>
            <option value="en">English</option>
            <option value="cs">Česky</option>
          </select>
        </li>
                  
      </ul>
      <div className="navbar-toggle" onClick={handleToggle}> 
      <FaBars className={`${isActive ? 'close' : ''} logo-icons`}/>
      <FaTimes className={`${isActive ? '' : 'close'} logo-icons`} />
      </div>
    </nav>
    </LocaleContext.Provider>
  );
};


export default Navbar;