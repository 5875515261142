
/* Components */

import Footer from "../../components/footer/footer.component"

/* SVG, IMG */
import { ReactComponent as Office } from "../../assets/office.svg";
import { ReactComponent as Reports } from "../../assets/reports.svg";
import { ReactComponent as Email } from "../../assets/Emails-pana.svg";
import { ReactComponent as Analysis } from "../../assets/Analysis.svg";
import { ReactComponent as Meiro } from "../../assets/reference/Meiro.svg"
import { ReactComponent as RemaTipTop } from "../../assets/reference/logo.svg"
import { ReactComponent as Keboola } from "../../assets/reference/keboola.svg"
import {ReactComponent as Mappi } from "../../assets/reference/mappi.svg"

import dataLigence from "../../assets/reference/Dataligence.png"
import travelKing from "../../assets/reference/travelking.png"
import dataIcon from "../../assets/reference/data-report.png"
import weron from "../../assets/reference/weron_2.png"
import minespol from "../../assets/reference/Minespol.png"
import {ReactComponent as LogoFull} from "../../assets/reference/full-icon.svg"
import { ReactComponent as Up } from "../../assets/reference/up.svg"
import { ReactComponent as Down } from "../../assets/reference/down.svg"
import LinkedIn from "../../assets/reference/linkedin.png"
import { useTranslation} from 'react-i18next'

import "./home.style.scss"
import Navbar from "../../components/navigation/navigation.component";

const Home = () => {
  const { t } = useTranslation();
 

  const handleEmailClick = () => {
      const email = "info@iiidmic.com"
      const subject = "Poptávka"
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
      window.location.href = mailtoLink;
    }
  return(
  <>
    <Navbar />
    {/* menu */}
    <div className="background">
      <div class="container-logo">
        <div>
        <LogoFull className="width_logo"/> 
        <p><span id="span-style">IIIDM </span>je inovativní společnost specializující se na práci s daty, nabízející široké spektrum služeb od tvorby databází a datových aplikací až po analýzu a vizualizaci dat. Mezi její klienty patří známé společnosti jako <span>Keboola</span> a <span>Travelking</span>, což svědčí o jejich odbornosti a důvěryhodnosti v oboru.</p>
        <button className="button-style" onClick={handleEmailClick}>{t('contact')}</button>
        </div>
        
        <img src={dataIcon} alt="data-icon" width={360} height={360}/>
      </div>
    </div>
    <main>
      <div className="citation sections-space">
        <Down width={30} height={30} className="position-down" />
        <p><span style={{fontWeight:"bold"}}>IIIDM</span> {t('quotes')}</p>
        <Up width={30} height={30} className="position-up" />
      </div>

      {/* Take care of you */}
      <div class="containers-take-care sections-space">
        <div className="container-take-care">
          <Analysis className="image-analysis" />
        </div>
        <div className="container-take-care">
          <h2>{t('takeCare.header')}</h2>
          <ul>
            <li>{t('takeCare.offer-1')}</li>
            <li>{t('takeCare.offer-2')}</li>
            <li>{t('takeCare.offer-3')}</li>
            <li>{t('takeCare.offer-4')}</li>
            <li>{t('takeCare.offer-5')}</li>
          </ul>
        </div>
      </div>

      {/*  Služby */}
      <div className="sections-space">
        <h1 className="service" id="service">{t('services.header')}</h1>
        <div className="containers-service">
          <div className="container-service">
            <h2>{t('services.title-1')}</h2>
            <p>{t('services.description-1')}</p>
          </div>

          <div className="container-service">
            <h2>{t('services.title-2')}</h2>
            <p>{t('services.description-2')}</p>
          </div>

          <div className="container-service">
            <h2>{t('services.title-3')}</h2>
            <p>{t('services.description-3')}</p>
          </div>

          <div className="container-service">
            <h2>{t('services.title-4')}</h2>
            <p>{t('services.description-4')}</p>
          </div>

          <div className="container-service">
            <h2 id="modeling">{t('services.title-5')}</h2>
            <p>{t('services.description-5')}</p>
          </div>
        </div>

      </div>

      {/*  Reference*/}
      <h2 id="reference" className="reference-h2">{t('reference.header')}</h2>
      <div className="reference">
        <div className="container-containers">
          <a href="https://www.keboola.com/" alt="Keboola" target={"_blank"} rel="noopener noreferrer">
            <div className="container">
              <div className="width" ><Keboola /></div>
              <div className="paragraph-size">
                <p>{t('reference.KeboolaDescription')}</p>
              </div>
            </div>
          </a>
          <a href="https://www.rematiptop.cz/" alt="Rema Tip Top" target={"_blank"} rel="noopener noreferrer">
            <div className="container">
              <div className="width">
                <RemaTipTop />
              </div>
              <div className="paragraph-size">
                <p>{t('reference.RemaDescription')}</p>
              </div>
            </div>
          </a>
          <a href="https://dataligence.cz/" target={"_blank"} rel="noopener noreferrer">
            <div className="container">
              <div className="width width-da"><img src={dataLigence} alt="Dataligence" style={{"width":"100%"}} /></div>
              <div className="paragraph-size">
                <p>{t('reference.DataligenceDescription')}</p>
              </div>
            </div>
          </a>
          <a href="https://travelking.cz/" target={"_blank"} rel="noopener noreferrer">
            <div className="container">
              <div className="width width-more"><img src={travelKing} alt="TravelKing"  style={{"width":"100%","padding":"10px"}} /></div>
              <div className="paragraph-size">
                <p>{t('reference.TravelKingDescription')}</p>
              </div>
            </div>
          </a>
          
          <a href='https://www.meiro.io/' alt="Meiro" target={"_blank"} rel="noopener noreferrer">
            <div className="container">
              <div className=" width width-me"><Meiro/></div>
              <div className="paragraph-size">
                <p>{t('reference.MeiroDescription')}</p>
              </div>
            </div>
          </a>


          <a href="https://www.weron.cz/" alt="Weron" target={"_blank"} rel="noopener noreferrer">
            <div className="container">
              <div className="width width-w"><img src={weron} alt="Weron"  style={{"width":"100%","padding":"10px"}}/>
              </div>
              <div className="paragraph-size">
                <p>{t('reference.Weron')}</p>
              </div>
            </div>
          </a>

          <a href="https://www.mimappi.com/" alt="Weron" target={"_blank"} rel="noopener noreferrer">
            <div className="container">
              <div className="width width-w"><Mappi style={{"margin-top":"10px"}}/>
              </div>
              <div className="paragraph-size">
                <p>{t('reference.Mappi')}</p>
              </div>
            </div>
          </a>

          <a href="https://www.weron.cz/" alt="Weron" target={"_blank"} rel="noopener noreferrer">
            <div className="container">
              <div className="width width-w"><img src={minespol} alt="Minespol" width={200} style={{"width":"90%","margin":"-30px"}}/>
              </div>
              <div className="paragraph-size">
                <p>{t('reference.Minespol')}</p>
              </div>
            </div>
          </a>
        </div>
      </div>

      {/* About us section*/}
      <div className="about-us sections-space" id="about">
        <h2>{t('aboutUs.header')}</h2>
        <p>{t('aboutUs.description-1')}</p>
        <p>{t('aboutUs.description-2')}</p>
      </div>


      {/* jobs */}
      <div className="next-align-items sections-space" id="career">
        <Office className="image" />
        <div className="career">
          <h2>{t('jobs.header')}</h2>
          <div>
            <h3>{t('jobs.jobs-description')}</h3>
            <p><span>{t('jobs.detail-1-span')}</span> {t('jobs.detail-1')}</p>
            <p><span>{t('jobs.detail-2-span')}</span> {t('jobs.detail-2')}</p>
            <p><span>{t('jobs.detail-3-span')}</span> {t('jobs.detail-3')}</p>
            <p><span>{t('jobs.detail-4-span')}</span> {t('jobs.detail-4')}</p>
          </div>
        </div>
      </div>



      {/*  Contact */}
      <div className="next-align-items sections-space">
        <div className="contact-details" id="contact">
          <h2>{t('contactDetail.header')}</h2>
          <h3>{t('contactDetail.name')}</h3>
          <p><span>{t('contactDetail.tel-span')}</span> {t('contactDetail.tel')}</p>
          <p><span>{t('contactDetail.ic-span')}</span> {t('contactDetail.ic')}</p>
          <p><span>{t('contactDetail.email-span')}</span> {t('contactDetail.email')}</p>
          <p><span>{t('contactDetail.billing-email-span')}</span> {t('contactDetail.billing-email')}</p>
          <p><span>{t('contactDetail.residence-span')}</span> {t('contactDetail.residence')} </p>
          <p><span>{t('contactDetail.office-span')}</span> {t('contactDetail.office')}</p>
          <a href="https://cz.linkedin.com/company/iiidmic" target={"_blank"} rel="noopener noreferrer"> <img src={LinkedIn} alt="linked-in" width={30} className="iconLinkedIn" /> </a>
        </div>
        <Email className="width-size image" />
      </div>


    </main>
    <Footer />
  </>
  )
}


export default Home;
