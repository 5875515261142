import "../footer/footer.style.scss"
const Footer = () => {
    return (
        <div className="background-footer">
        <p>© 2023 Společnost IIIDM international consulting s.r.o. </p>
        </div>
        
    )
  }

export default Footer;